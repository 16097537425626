
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup () {
    const loading = ref<boolean>(false)
    return {
      loading
    }
  }
})
